import React from "react";
import List from "../../shared/List";
import helpers from "../../shared/helpers";
import EventList from "./EventList";
import EventSearch from "./EventSearch";

export default function Events(){

  return (<>
    <h2>Events</h2>
    <EventSearch/>
    <EventList type="pending" limit={30} title="Pending Event Listings"/>
    <EventList type="approved" limit={10} title="Approved Event Listings"/>


  </>);
}
