const helpers = {
  dateTime:function(timestamp){
    var months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    var date = new Date(new Date(timestamp).toLocaleString("en-US", {timeZone: "Etc/UTC"}));
    var month = date.getMonth();
    var day = date.getDate();
    var year = date.getFullYear();
    var hour = date.getHours();
    var minute = date.getMinutes();

    if(minute < 10){
      minute = "0"+minute;
    }
    var prefix = "AM";
    if(hour == 0){
      hour = "12";
    }
    else if(hour > 11){
      prefix = "PM";
      if(hour > 12){
        hour = hour -12;
      }
    }

    return months[month]+" "+day+", "+year+" "+hour+":"+minute+" " + prefix;
  },
  date:function(timestamp){
    var months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    var date = new Date(new Date(timestamp).toLocaleString("en-US", {timeZone: "Etc/UTC"}));
    var month = date.getMonth();
    var day = date.getDate();
    var year = date.getFullYear();

    return months[month]+" "+day+", "+year;
  }

};
export default helpers;
