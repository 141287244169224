import logo from './logo.svg';
import './style/base.scss';
import {UserContextProvider} from "./userContext";
import {BrowserRouter as Router, Routes, Route,useLocation} from "react-router-dom";

import Admin from "./Admin";

function App() {

  const toggleMenu = (e) => {
    var menu = document.querySelector("ul.menu");

    if(menu){
      if(menu.classList.contains("show")){
        menu.classList.remove("show");
        e.currentTarget.classList.remove("close");
      }
      else{
        menu.classList.add("show");
        e.currentTarget.classList.add("close");
      }
    }
  }

  return (<>
    <div className="header">
      <div className="menuToggle" onClick={toggleMenu}>
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon open" viewBox="0 0 512 512"><title>Menu</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 160h352M80 256h352M80 352h352"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon close" viewBox="0 0 512 512"><title>Close</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
      </div>
        <img src="https://hubhawaii.com/static/logo-black.png" className="logo"/>
      <h1>Admin Console</h1>
    </div>
    <div className="container">
    <Router>
      <UserContextProvider admin={true}>
        <Admin/>
      </UserContextProvider>
    </Router>
    </div>
  </>);
}

export default App;
