import React from "react";
import CommentsList from "./CommentsList";
import FlaggedCommentsList from "./FlaggedCommentsList";

export default function Comments(){
  return (<div className="Comments">
      <h2>Comments</h2>
      <FlaggedCommentsList limit={10}/>
      <CommentsList title="All Comments" limit={30}/>
      
    </div>);
}
