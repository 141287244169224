import React from "react";
import List from "../../shared/List";
import UserPopup from "../../shared/UserPopup";

import Popup from "../../shared/Popup";
import helpers from "../../shared/helpers";
import config from "../../config";
import {userContext} from "../../userContext";
import "../../style/Comments.scss";


export default function CommentsList({title,limit,filter,titleLink}){

  const [showPopup,setShowPopup] = React.useState(false);
  const [comment,setComment] = React.useState(false);
  const [actionLoading,setActionLoading] = React.useState(false);
  const [actionSuccess,setActionSuccess] = React.useState(false);
  const [userID,setUserID] = React.useState(false);
  const {user} = React.useContext(userContext);
  const [updateData,setUpdateData] = React.useState([]);


  const showOptions = (focusComment) => {
    setComment(focusComment);
    setShowPopup(true);
  };

  const hidePopup =()=>{
    setActionLoading(false);
    setActionSuccess(false);
    setShowPopup(false);
    setComment(false);
  }

  const commentAction = (action,data,loading) => {
    setActionLoading(loading);
    var formData = new FormData;
    formData.append('id',user.id);
    formData.append('token',user.token);

    Object.keys(data).forEach(k=>{
      formData.append(k,data[k]);
    });

    fetch(config.comments.API_DOMAIN+"api/comments/admin/"+action,{
      method:"POST",
      headers:{
        'Accept':'application/json'
      },
      body:formData
    }).then(res=>res.json())
    .then(rdata=>{
      setActionSuccess(rdata.message);
      if(action == "hide"){
        setUpdateData({
          key:'id',
          keyValue:data.commentid,
          newData:{hidden:true}
        });
      }
      else if(action == "unhide"){
        setUpdateData({
          key:'id',
          keyValue:data.commentid,
          newData:{hidden:false}
        });
      }
      else if(action == "delete"){
        setUpdateData({
          key:'id',
          keyValue:data.commentid,
          newData:"delete"
        });
      }
      else if(action == "silence"){
        setUpdateData({
          key:'authorid',
          keyValue:data.userid,
          newData:{hidden:true,user_silence:true}
        });

      }
      else if(action == "unsilence"){
        setUpdateData({
          key:'authorid',
          keyValue:data.userid,
          newData:{user_silence:false}
        });

      }
      else if(action == "banuser"){
        setUpdateData({
          key:'authorid',
          keyValue:data.userid,
          newData:{user_ban:true,hidden:true}
        });

      }
      else if(action == "unbanuser"){
        setUpdateData({
          key:'authorid',
          keyValue:data.userid,
          newData:{user_ban:false}
        });

      }
    }).catch(e=>{
      hidePopup();
    });
  };


  return (<div className="Comments">
    <UserPopup id={userID} hide={()=>setUserID(false)}/>
    <Popup
     show={showPopup}
     hide={hidePopup}>
     {actionLoading ?
       (<>
         {actionSuccess ? (<>
           <center>{actionSuccess}</center>
           <ul className="optionsList">
            <li onClick={hidePopup}>OK</li>
           </ul>
         </>) : (<center>{actionLoading}</center>)}
       </>) : (
        <ul className="optionsList">
         {comment.hidden ? (
           <li onClick={()=>commentAction('unhide',{commentid:comment.id},'Un-Hiding Comment')}>Un-Hide Comment</li>
         ) : (<li onClick={()=>commentAction('hide',{commentid:comment.id},'Hiding Comment')}>Hide Comment</li>)}
          <li onClick={()=>commentAction('delete',{commentid:comment.id},'Deleting Comment')}>Delete Comment</li>
          {comment.user_ban ? (
            <li onClick={()=>commentAction('unbanuser',{userid:comment.authorid},'Un-Banning User '+comment.author+ ' ('+comment.username+')' )}>Un-Ban User {comment.author}</li>
          ) : (
            <li onClick={()=>commentAction('banuser',{userid:comment.authorid},'Banning User '+comment.author+ ' ('+comment.username+')' )}>Ban User {comment.author}</li>
          )}
          {comment.user_silence ? (
            <li onClick={()=>commentAction('unsilence',{userid:comment.authorid},'Un-Silencing User '+comment.author+ ' ('+comment.username+')' )}>Un-Silence User {comment.author}</li>
          ) : (<li onClick={()=>commentAction('silence',{userid:comment.authorid},'Silencing User '+comment.author+ ' ('+comment.username+')' )}>Silence User {comment.author}</li>)}
          <li onClick={hidePopup} className="cancel">Cancel</li>
         </ul>
       )}
    </Popup>
    <List
      title={title}
      titleLink={titleLink}
      limit={limit}
      filter={filter}
      updateData={updateData}
      urlGET={`${config.comments.API_DOMAIN}api/comments/admin/list`}
      template={(i,index)=>{
        return (
          <tr key={`comment${index}`}>
            <td>
              <div className={`comment ${i.hidden && `hidden`} ${i.flags > 0 && `hasFlags`}`}>
                <div className="options" onClick={()=>showOptions(i)}>
                  <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Ellipsis Vertical Circle</title><circle fill="currentColor"  cx="256" cy="256" r="26"/><circle fill="currentColor" cx="256" cy="346" r="26"/><circle cx="256" cy="166" fill="currentColor"  r="26"/><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>
                </div>
                <div className="commentUser">
                  <img onClick={()=>setUserID(i.authorid)} src={i.picture}/>
                  <b>{i.timeadded}</b>
                  <div className="user">
                    Posted by <b>{i.author}</b> ({i.username})
                     {i.user_ban && (<div className='label ban'>BANNED</div>)}
                     {i.user_silence && (<div className='label silence'>SILENCED</div>)}
                  </div>

                  {i.location !== "None" && (<i>Location: {i.location} ({i.coords})</i>)}
                  <a href={i.topicurl} target="_blank" className="topic">{i.topic}</a>
                </div>
                <div className="comment" dangerouslySetInnerHTML={{ __html: i.content.replace(/\\r/g,"") }}></div>
                <div className="stats">
                  <div className={`flags ${i.flags > 0 && `hasFlags`}`}>{i.flags} Flag{(i.flags == 0 || i.flags > 1) && `s`}</div>
                  <div className="likes">{i.likeCount} Like{(i.likeCount == 0 || i.likeCount > 1) && `s`}</div>
                  <div className="dislikes">{i.dislikeCount} Dislike{(i.dislikeCount == 0 || i.dislikeCount > 1) && `s`}</div>

                </div>

              </div>
            </td>
          </tr>
        )
      }}
    />
  </div>);
}
