import React from "react";
import List from "../../shared/List";
import helpers from "../../shared/helpers";
import EventList from "./EventList";
import { useParams } from "react-router-dom";
import EventSearch from "./EventSearch";

export default function EventsIsland(){
  const {island} = useParams();
  
  return (<>
    <h2>{island.toUpperCase()} Events</h2>
    <EventSearch island={island}/>
    <EventList type="pending" limit={30} title="Pending Event Listings" island={island}/>
    <EventList type="approved" limit={10} title="Approved Event Listings" island={island}/>


  </>);
}
