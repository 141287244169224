import { useState,useRef,useEffect } from "react";
import "./eventSearch.scss";
import EventList from "./EventList";
import config from "../../config";
import {ajaxPOST,ajaxGET} from "../../ajax";

export default function EventSearch({island}){
    const [query,setQuery] = useState();
    const [pendingQuery,setPendingQuery] = useState("");
    const [active,setActive] = useState(false);
    const [results,setResults] = useState(false);
    const [focusItem,setFocusItem] = useState(false);
    const [actionLoading,setActionLoading] = useState(false);
    const [actionSuccess,setActionSuccess] = useState(false);
    const [selected,setSelected] = useState([]);
    const [updateData,setUpdateData] = useState(false);
    const checkboxRef = useRef([]);
    const [searchKey,setSearchKey] = useState(false);

    useEffect(()=>{
        if(searchKey){
            setSearchKey(new Date().getTime());
        }
    },[active]);

    const search = () => {
        setQuery(pendingQuery);
        setSearchKey(new Date().getTime());
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (<div className="eventSearch">
        <div className="searchbar">
            <input placeholder={`Search ${island ? capitalizeFirstLetter(island) : ``} Events`} type="text" value={pendingQuery} onChange={(e)=>setPendingQuery(e.target.value)}/>
            <button disabled={query == ""} onClick={search}>Search</button>
        </div>
        <label><input type="checkbox" checked={active} onChange={(e)=>setActive(e.target.checked)}/> Only Show Active Listings</label>
        {searchKey ? (<div className="List">
            <h3>Search Results for {query}</h3>
            <EventList active={active} island={island} limit={20} query={query} key={searchKey}/>
        </div>) : (<></>)}

    </div>)
}