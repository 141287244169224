import React from "react";
import List from "../../shared/List";
import helpers from "../../shared/helpers";
import config from "../../config";
import Popup from "../../shared/Popup";
import Form from "../../shared/Form";
import {ajaxPOST,ajaxGET} from "../../ajax";
import {eventLink} from "../Events/helpers";


export default function PaymentsList({type,slug,title,limit}){

  const [showPopup,setShowPopup] = React.useState(false);
  const [actionSuccess,setActionSuccess] = React.useState(false);
  const [actionLoading,setActionLoading] = React.useState(false);
  const [focusItem,setFocusItem] = React.useState(false);
  const [focusItemDetails,setFocusItemDetails] = React.useState(false);
  const [optionItem,setOptionItem] = React.useState(false);
  const [updateData,setUpdateData] = React.useState(false);
  const [showForm,setShowForm] = React.useState(false);

  const hidePopup = () => {
    setShowPopup(false);
    setActionLoading(false);
    setActionSuccess(false);
    setOptionItem(false);
  };

  const showOptions = (item) => {
    setOptionItem(item);
    setShowPopup(true);

  };


  React.useEffect(()=>{
    if(!focusItem) return;
    if(focusItem?.details?.eventHash && !focusItem.event){
      (async ()=>{
        var slug = "maui";
        if(focusItem.program == "eventsbig-island"){
          slug = "bigisland";
        }
        var event = await ajaxGET(config.events.API_DOMAIN+"get",{'eventHash':focusItem.details.eventHash,'slug':slug});
        setFocusItem({...focusItem,event});
      })();
      

    }
    console.log(focusItem);
    
  },[focusItem]);

  const rowAction = async (action,data,loading) => {
    setActionLoading(loading);
    var formData = new FormData;

    var result = await ajaxPOST(config.events.API_DOMAIN+action,data);

    setActionSuccess(result.message);
    if(action == "delete" || action == "approve"){
      setUpdateData({
        key:'eventHash',
        keyValue:data.eventHash,
        newData:"delete"
      });
    }
  };

  React.useEffect(async ()=>{
    if(optionItem === false) return;
    var result = await ajaxGET(config.obituaries.API_DOMAIN+"api/get",{'deathrecordid':optionItem.deathrecordid,'slug':slug});
    if(result){
      setOptionItem({...result,details:result.details.replace(/<p\>/g,"\n\n")});
    }
  },[showForm]);

  const formatTime = (dateString) => {
    var dateObj = new Date(dateString);
    var timeString = dateObj.getHours() + ":"  + dateObj.getMinutes();
    return (dateObj.getMonth() + 1) + "/" + dateObj.getDate() + "/" + dateObj.getFullYear() + " " + timeString;
  };


  return (<>


    <Popup
     show={showPopup}
     hide={hidePopup}>
     {actionLoading ?
       (<>
         {actionSuccess ? (<>
           <center>{actionSuccess}</center>
           <ul className="optionsList">
            <li onClick={hidePopup}>OK</li>
           </ul>
         </>) : (<center>{actionLoading}</center>)}
       </>) : (<>
         <b style={{display:"block",textAlign:"center",paddingBottom:10}}>{optionItem.title}</b>
        <ul className="optionsList">
          {(focusItem.active !== "true") && (<li onClick={()=>rowAction("approve",{'eventHash':optionItem.eventHash,'slug':slug},"Approving Event Listing")} className="">Approve Event</li>)}
          <li onClick={()=>rowAction("delete",{'eventHash':optionItem.eventHash,'slug':slug},"Deleting Event")} className="">Delete Event</li>
          <li onClick={()=>window.open("https://mauinow.com/eventbeta/edit/"+optionItem.eventHash)}>Edit Event</li>

          <li onClick={hidePopup} className="cancel">Cancel</li>
         </ul>
       </>)}
    </Popup>

    <Popup
      show={focusItem}
      hide={()=>{ setFocusItem(false); setFocusItemDetails(false); }}
    > 
      <div className="details">
        <h2 style={{marginBottom:0,paddingBottom:0}}>Payment Details</h2>


        <div className="section">
          <b>Amount</b>
          ${focusItem.details?.grandTotal.toFixed(2)}
        </div>

        <div className="section">
          <b>Billing E-Mail</b>
          {focusItem.customer?.billingemail}
        </div>

        {(focusItem.details?.recurringNext && focusItem.active) ? (<>
          <div className="section">
            <b>Next Recurring Payment</b>
            {focusItem.details?.recurringNext.dateString}
          </div>
        </>) : (<>
          <div className="section">
            <b>Paid On</b>
            {helpers.dateTime(focusItem.timeUpdated)}
          </div>
        </>)}

        {(focusItem.event) ? (<>
          <div className="section">
            <b>Event Details:</b>
            <a href={eventLink(focusItem.event)} target="_blank">{focusItem.event.title}</a>
            <br/><span dangerouslySetInnerHTML={{__html:focusItem.event.description}}></span>

          </div>
        </>) : (<></>)}

        {focusItem.adminData ? (<>
          {Object.keys(focusItem.adminData).map(k=>{
            if(typeof focusItem.adminData[k] === "string"){
              return (<div className="section">
                <b>{k}</b>
                {focusItem.adminData[k]}
              </div>);
            }
          })}
        </>) : (<></>)}






      </div>

    </Popup>

    <Popup
      hide={()=>setShowForm(false)}
      show={showForm}
    >
      <Form
        initialData={optionItem}
        editKey="eventHash"
        fields={[
          {name:'title',label:'Name of Deceased',type:'text',required:true},
          {name:'details',label:'Obituary',type:'textarea',required:true},
          {name:'birthdate',label:'Birthday',type:'date',required:true},
          {name:'deathdate',label:'Date of Death',type:'date',required:true},

        ]}
      />
    </Popup>

    <List
      title={title}
      limit={limit}
      updateData={updateData}
      urlGET={`${config.payments.API_DOMAIN}schedules?type=${type}`}
      template={(i,index)=>{
        return (
          <tr key={`item${index}`}>
            <th onClick={()=>setFocusItem(i)} style={{textDirection:"underline"}}>${i.details.grandTotal.toFixed(2)}</th>
            <td>{i.details.recurringNext ? i.details.interval : `onetime`}</td>
            <td>{i.program}</td>
            <td>
              {type == "paid" ? (<>
                Paid on {helpers.dateTime(i.timeUpdated)}

              </>) : (<>
                Upcoming {i.details.recurringNext.dateString}
                <br/><i>{formatTime(i.details.recurringNext.date)}</i>
              </>)}
              
            </td>
            
            <td style={{minWidth:"50px"}}>
              <div className="options" onClick={()=>showOptions(i)}>
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Ellipsis Vertical Circle</title><circle fill="currentColor"  cx="256" cy="256" r="26"/><circle fill="currentColor" cx="256" cy="346" r="26"/><circle cx="256" cy="166" fill="currentColor"  r="26"/><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>
              </div>
            </td>
          </tr>
        )
      }}
    />
  </>);
}
