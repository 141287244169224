import React from "react";
import BusinessDirectoryList from "./BusinessDirectoryList";
import ListingCount from "./ListingCount";
import "../../style/BusinessDirectory.scss";

export default function Comments(){
  return (<div className="BusinessDirectory">
      <h2>Business Directory</h2>
      <center><ListingCount/></center>
      <BusinessDirectoryList title="Pending Approval" limit={30}/>
    </div>);
}
