import React from "react";
import "../style/Popup.scss";


export default function Popup(props){
  const {children,show,hide,disableUnFocus} = props;


  return (
  <div className={`Popup ${show && `show`}`} onClick={(e)=>{ if(e.target == e.currentTarget){ hide(); console.log("CLICKED");  }}}>
    <div className="popupHolder">
      <button className="close" onClick={(e)=>{ e.preventDefault(); hide(); }}>&times;</button>
      {children}
    </div>
  </div>
  )

}
