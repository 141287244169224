import React from "react";
import List from "../../shared/List";
import helpers from "../../shared/helpers";
import ObituaryList from "./ObituaryList";

export default function Obituaries(){

  return (<>
    <h2>Obituaries</h2>

    <ObituaryList type="pending" slug="maui" title="Pending (Maui)"/>
    <ObituaryList type="pending" slug="bigisland" title="Pending (Big Island)"/>

    <ObituaryList type="approved" slug="maui" title="Approved (Maui)" limit={25}/>
    <ObituaryList type="approved" slug="bigisland" title="Approved (Big Island)" limit={25}/>


  </>);
}
