import React from "react";
import {userContext} from "./userContext";
import {BrowserRouter as Router, Routes, Route,useLocation} from "react-router-dom";
import Home from "./screens/Home";
import Comments from "./screens/Comments";
import BusinessDirectory from "./screens/BusinessDirectory";
import Obituaries from "./screens/Obituaries";
import Events from "./screens/Events";
import EventsIsland from "./screens/Events/EventsIsland";
import Payments from "./screens/Payments";
import Menu from "./screens/Menu";
import UserPopup from "./shared/UserPopup";


export default function Admin(props){
  const {user,logout} = React.useContext(userContext);
  const location = useLocation();
  const [userid,setUserid] = React.useState(false);

  React.useEffect(()=>{
    var menu = document.querySelector("ul.menu");
    if(menu){
      menu.classList.remove("show");
      document.querySelector(".menuToggle").classList.remove("close");
    }
  },[location]);

  return (<>
    <UserPopup id={userid} hide={()=>setUserid(false)}/>

    <div className="holder">
      <img className="headerProfile" src={user.profile.picture} onClick={()=>setUserid(user.id)}/>
        <Menu/>
        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/comments" element={<Comments/>}/>
          <Route path="/events" element={<Events/>}/>
          <Route path="/events/:island" element={<EventsIsland/>}/>
          <Route path="/obituaries" element={<Obituaries/>}/>
          <Route path="/businessdirectory" element={<BusinessDirectory/>}/>
          <Route path="/payments" element={<Payments/>}/>

        </Routes>

    </div>
  </>);
}
