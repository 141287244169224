import React from "react";
import "../style/Form.scss";
import FormDate from "./FormDate";

export default function Form({fields,initialData,buttonEdit,buttonNew}){
  const [data,setData] = React.useState(initialData||{});

  React.useEffect(()=>{
    setData(initialData);
  },[initialData]);



  const formComponents = fields.map((f,index)=>{
    if(f.type == "text"){
      return (
        <label key={`formField${index}`}>
          {f.label}
          <input type="text" required={f.required} value={data[f.name] || ""} onChange={(e)=>setData({...data,[f.name]:e.target.value})}/>
        </label>
      );
    }
    else if(f.type == "textarea"){
      return (
        <label key={`formField${index}`}>
          {f.label}
          <textarea required={f.required} onChange={(e)=>setData({...data,[f.name]:e.target.value})} value={data[f.name] || ""}/>
        </label>
      );
    }
    else if(f.type == "date"){
      return (
        <label key={`formField${index}`}>
          <FormDate initialValue={data[f.name]} onChange={(newValue)=>setData({...data,[f.name]:newValue})}/>
        </label>
      )
    }
  });

  const submit = (e) => {
    e.preventDefault();
    alert("SUBMIT IT");
  }

  return (
    <form className="Form" onSubmit={submit}>
      {formComponents}
      {initialData ?
        (<button type="submit">{buttonEdit || "Edit"}</button>) :
        (<button type="submit">{buttonNew || "Submit"}</button>)
      }
    </form>

  )

}
