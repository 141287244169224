import React from "react";

export const userContext = React.createContext();

export function UserContextProvider({children,admin}){

  const [scriptLoaded,setScriptLoaded] = React.useState(false);
  const [user,setUser] = React.useState(false);
  const [authenticated,setAuthenticated] = React.useState(false);
  const [loginFormLoaded,setLoginFormLoaded] = React.useState(false);
  const loginForm = React.createRef();

  React.useEffect(()=>{
    if(scriptLoaded) return;

    var style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href = "https://media.hubhawaii.com/public/css/hubhawaii.frontend2.min.css";
    document.body.appendChild(style);

    var script = document.createElement("script");
    script.src = "https://media.hubhawaii.com/public/js/hubhawaii.frontend2.min.js";
    script.onload = () => {
      setScriptLoaded(true);
    };
    document.body.appendChild(script);


    window.addEventListener("hubHawaiiLogin",()=>{
      if(window.hh2020Login && window.hh2020Login.userData){
        setUser(window.hh2020Login.userData);
        window.hh = {};
        window.hh.id = window.hh2020Login.userData.id;
        window.hh.token = window.hh2020Login.userData.token;
        setAuthenticated(true);
      }

    });

    window.addEventListener("hubHawaiiLogout",()=>{
      window.location.reload();
    });

  },[]);

  React.useEffect(()=>{

  },[user]);

  React.useEffect(()=>{
    if(!scriptLoaded) return;

    loadLoginForm();

  },[scriptLoaded]);

  const loadLoginForm = (skipScreen) => {

    window.hubHawaiiScreen.load();

    window.hh2020Login.createScreen("",loginForm.current);
    setLoginFormLoaded(true);
  };

  const logout = (e) => {
    e.preventDefault();
    window.hh2020Login.logout();
    setAuthenticated(false);
    setUser(false);

  }


  return (<userContext.Provider value={{user,logout}}>

    {scriptLoaded && (<>

      {authenticated ? (<>
        {(admin && user.profile.admin) ? <>{children}</> :
          !admin ? (<>{children}</>) : (
        <>
          Insufficient Access
          <br/><a href="#" onClick={logout}>Login as another user</a>
        </>)}

      </>) : (

          <div className="loginForm" id="hhLoginForm" ref={loginForm}></div>

      )}
    </>)}
  </userContext.Provider>)

};
