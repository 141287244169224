import React from "react";
import {ajaxGET,ajaxPOST} from "../../ajax";
import config from "../../config";

export default function ListingCount(){
  const [data,setData] = React.useState({});
  const regions = {
    "maui":"Maui",
    "bigisland":"Big Island",
    "kauai":"Kauai"
  };

  React.useEffect(async ()=>{

    let result = await ajaxGET(config.businessdirectory.API_DOMAIN + "api/directory/v2/count",{});
    if(!result.error){
      setData(result);
    }

  },[]);

  const countComponents = Object.keys(regions).map(k=>{
    return (<div className="listingCount">
      <b>{regions[k]}</b>
      {data[k] ? <>{data[k]}</> : `-`}

    </div>);
  });

  return (<>
    {countComponents}
  </>)


}
