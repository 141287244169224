import React from "react";
import ObituaryList from "./Obituaries/ObituaryList";
import CommentsList from "./Comments/CommentsList";
import EventList from "./Events/EventList";
import BusinessDirectoryList from "./BusinessDirectory/BusinessDirectoryList";

export default function Home(){
  return (<>
    <EventList type="pending" limit={5} title="Event Approvals" titleLink={(<a href="/events">View All Events</a>)}/>
    <CommentsList limit={10} title="Recent Comments" titleLink={(<a href="/comments">View All Comments</a>)}/>
  </>);
}
