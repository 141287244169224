import React from "react";
import "../style/FormDate.scss";
import helpers from "./helpers";
import Popup from "./Popup";

export default function FormDate({onChange,initialValue}){
  const [value,setValue] = React.useState(initialValue);
  const [showCalendar,setShowCalendar] = React.useState(false);
  const [selectedYear,setSelectedYear] = React.useState(false);
  const [selectedMonth,setSelectedMonth] = React.useState(false);
  const [selectedDay,setSelectedDay] = React.useState(false);
  const [year,setYear] = React.useState(false);
  const [month,setMonth] = React.useState(false);
  const [day,setDay] = React.useState(false);
  const calendarElement = React.useRef(false);
  const calendarDates = React.useRef([]);
  const [dateGrid,setDateGrid] = React.useState([]);

  React.useEffect(()=>{
    var defaultValue = initialValue || new Date().getTime();
    setValue(defaultValue);
    var timestamp = defaultValue;
    if(!timestamp || timestamp === undefined){
      timestamp = new Date().getTime();
    }
    var dateObj = new Date();
    dateObj.setTime(timestamp);
    setYear(dateObj.getFullYear());
    setMonth(dateObj.getMonth());
    setDay(dateObj.getDate());

    setSelectedYear(dateObj.getFullYear());
    setSelectedMonth(dateObj.getMonth());
    setSelectedDay(dateObj.getDate());
  },[initialValue]);

  React.useEffect(()=>{
    if(!showCalendar) return;
    updateCalendar();
  },[showCalendar]);

  React.useEffect(()=>{

  },[value]);

  React.useEffect(()=>{
    if(!showCalendar) return;

  },[showCalendar]);






  const updateCalendar = () => {
    var newGrid = [];
    var firstDay = new Date(year,month).getDay();
    var lastDate = 32- new Date(year,month,32).getDate();
    var date = 1;
    for(let row = 0;row < 6;row++){

      for(let col = 0;col<7;col++){
        var cell = {};

        cell['year'] = year;

        if(row == 0 && col < firstDay){
          cell['date'] = date;
          cell['month'] = month-1;
        }
        else if(date > lastDate){
          cell['date'] = date;
          cell['month'] = month+1;
          break;
        }
        else{
          cell['date'] = date;
          cell['clickable'] = true;
          cell['month'] = month;
          cell['timestamp'] = new Date(year,month,date);


          if(selectedDay == date && selectedMonth == month && selectedYear == year){
            cell['selected'] = true;
          }

          date++;
        }


        newGrid.push(cell);

      }
    }
    setDateGrid(newGrid);
  };



  React.useEffect(()=>{
    if(value && value !== initialValue){


      var dateObj = new Date();
      dateObj.setTime(value);
      setSelectedYear(dateObj.getFullYear());
      setSelectedMonth(dateObj.getMonth());
      setSelectedDay(dateObj.getDate());
      if(onChange){
        onChange(dateObj.getTime());
      }
    }
  },[value]);

  const dayComponents = dateGrid.map((d,index)=>{
    return (
      <div
        key={`${d.year}-${d.month}-${d.date}-${index}`}
        className={`day ${d.clickable && `filled`} ${d.selected && `selected`}`}
        onClick={()=>{
          if(d.clickable){
           setValue(d.timestamp);
          }
        }}
      >
        {d.clickable && d.date}
      </div>)
  });
  const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];


  return (
    <div className="FormDate">
      <Popup disableUnFocus={true} show={showCalendar} hide={()=>{ console.log("HIDE CALENDAR"); setShowCalendar(false); }}>
        <h2>{months[month]} {year}</h2>
        <div className="calendar">
        {dayComponents}
        </div>
      </Popup>
      <button className="toggleButton" onClick={(e)=>{ e.preventDefault(); setShowCalendar(true); }}>{helpers.date(value)}</button>
    </div>

  )

}
