export const ajaxGET = async (url,data) => {
  if(!window.hh || !window.hh.id){
    return;
  }
  var dataURL = url;
  if(dataURL.indexOf("?") > 0){
    dataURL += "&";
  }
  else{
    dataURL += "?";
  }
  dataURL += "id="+window.hh.id + "&token="+encodeURIComponent(window.hh.token)+"&";

  Object.keys(data).forEach(k=>{
    dataURL += k+"="+encodeURIComponent(data[k]) + "&";
  });

  var result = await new Promise((resolve,reject)=>{
    fetch(dataURL)
    .then(res=>res.json())
    .then(jData=>{
      resolve(jData);
    });
  });
  return result;


};

export const ajaxPOST = async (url,data) => {
  var dataURL = url;
  var formData = new FormData;
  formData.append('id',window.hh.id);
  formData.append('token',window.hh.token);


  Object.keys(data).forEach(k=>{
    formData.append(k,data[k]);
  });

  var result = await new Promise((resolve,reject)=>{
    fetch(dataURL,{
      method:"POST",
      headers:{
        'Accept':'application/json'
      },
      body:formData
    })
    .then(res=>res.json())
    .then(jData=>{
      resolve(jData);
    });
  });
  return result;


};
