export function eventLink(event){
    var prefix = "https://mauinow.com/events/event/";
    if(event.calendarID == "big-island"){
        prefix = "https://bigislandnow.com/events/event/";
    }
    if(event.calendarID == "kauai"){
        prefix = "https://kauainownews.com/community-calendar/event/";
    }
    return `${prefix}${event.eventHash}`;
}

export function eventEditLink(event){
    var prefix = "https://mauinow.com/events/edit/";
    if(event.calendarID == "big-island"){
        prefix = "https://bigislandnow.com/events/edit/";
    }
    if(event.calendarID == "kauai"){
        prefix = "https://kauainownews.com/community-calendar/edit/";
    }
    return `${prefix}${event.eventHash}`;
}