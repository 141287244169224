import React from "react";
import {NavLink} from "react-router-dom";

export default function Home(){
  return (<ul className="menu">
      <li><NavLink to="/">Home</NavLink></li>
      <li><NavLink to="/comments">Comments</NavLink></li>
      <li>
        <NavLink to="/events">Events</NavLink>
        <li className="sublink"><NavLink to="/events/maui">Maui Events</NavLink></li>
        <li className="sublink"><NavLink to="/events/big-island">Big Island Events</NavLink></li>
        <li className="sublink"><NavLink to="/events/kauai">Kauai Events</NavLink></li>  
      </li>

      <li><NavLink to="/payments">Payments</NavLink></li>

    </ul>);
}
