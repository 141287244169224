import React from "react";
import List from "../../shared/List";
import helpers from "../../shared/helpers";
import PaymentsList from "./PaymentsList";

export default function Events(){

  return (<>
    <h2>Payments</h2>
    <PaymentsList type="upcoming" limit={10} title="Upcoming Payments"/>
    <PaymentsList type="paid" limit={10} title="Processed Payments"/>


  </>);
}
